import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { Account, AccountStorage } from "@services/account";
import PageTop1 from "@/components-v2/layout/page-top1";
import PageTop from "@/components-v2/layout/page-top";
import PageBottom from "@/components-v2/layout/page-bottom";
var account = new Account();
export default {
  components: {
    PageBottom: PageBottom,
    PageTop: PageTop,
    PageTop1: PageTop1
  },
  data: function data() {
    return {
      payResults: 0,
      //1成功  0失败
      payStatua: this.$route.params.payType,
      orderId: this.$route.params.orderId
    };
  },
  mounted: function mounted() {
    this.isLogin = account.isLogined();
  },
  methods: {
    // 返回首页
    toHome: function toHome() {
      this.$router.replace({
        name: "v2-home"
      });
    },
    // 返回我的订单
    toMyOrder: function toMyOrder() {
      this.$router.replace({
        name: "v2-order",
        params: {
          status: "0"
        }
      });
    },
    topay: function topay() {
      this.$router.replace({
        name: "payMent",
        params: {
          orderId: this.orderId,
          type: "order"
        }
      });
    }
  }
};