var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop1'), _c('PageTop'), _c('div', {
    staticClass: "pay-ment-results"
  }, [_vm.payStatua === 'offline' || _vm.payStatua === 'SUCCESS' ? _c('img', {
    attrs: {
      "src": require("./image/icon_SUCCESS.png"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("./image/icon_FAIL.png"),
      "alt": ""
    }
  }), _vm.payStatua !== 'offline' ? _c('p', {
    staticClass: "results"
  }, [_vm._v(" " + _vm._s(_vm.payStatua === "SUCCESS" ? "支付成功" : "支付失败") + " ")]) : _vm._e(), _vm.payStatua === 'offline' ? _c('p', {
    staticClass: "results"
  }, [_vm._v(" 订单 " + _vm._s(_vm.orderId) + " 提交成功！ ")]) : _vm._e(), _vm.payStatua === 'offline' ? _c('p', {
    staticClass: "results-tips"
  }, [_vm._v(" 请在个人订单中心关注订单状态 ")]) : _vm._e(), _c('div', {
    staticClass: "button-box"
  }, [_c('p', {
    on: {
      "click": _vm.toHome
    }
  }, [_vm._v("返回首页")]), _vm.payStatua === 'offline' || _vm.payStatua === 'SUCCESS' ? _c('p', {
    on: {
      "click": _vm.toMyOrder
    }
  }, [_vm._v(" 我的订单 ")]) : _c('p', {
    on: {
      "click": _vm.toMyOrder
    }
  }, [_vm._v("查看订单")])])]), _c('PageBottom')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };